import { Component } from 'react';
import '../css/ProfileHeader.css';
import 'semantic-ui-css/semantic.min.css';
import potraitImage from '../img/Potrait.jpg'
import profileWallpaper from '../img/icon4.jpg'
import {
    Icon,
    Image,
    Container, Grid, Header
} from "semantic-ui-react";
import awsBadge from  '../img/AWS_Logo.png'
import { Link } from "react-router-dom";

interface ProfileHeaderProps {
}

interface ProfileHeaderState {
}

class ProfileHeader extends Component<ProfileHeaderProps, ProfileHeaderState>  {
    constructor(props: ProfileHeaderProps) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="profile-header-container">
                {/* Header Section */}
                <div className="profile-header">
                    <Container fluid>
                        <Image
                            src={profileWallpaper}
                            className="header-image"
                            alt="Header Wallpaper"
                        />
                        <div className="certification-badge">
                            <Image
                                src={awsBadge} // Replace this with the path to your AWS certification badge
                                alt="AWS Certification Badge"
                            />
                        </div>
                    </Container>
                </div>

                {/* Profile Section */}
                <div className="profile-details">
                    <Container>
                        <Grid>
                            <Grid.Row verticalAlign="middle" centered stretched>
                                <Grid.Column width={3}>
                                    {/* Profile Picture */}
                                    <div className="profile-picture-container">
                                        <Image
                                            src={potraitImage}
                                            circular
                                            className="profile-picture"
                                            alt="Profile Picture"
                                        />
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    {/* Profile Info */}
                                    <Header as="h1" className="profile-name">
                                        DEVANSH GUPTA
                                    </Header>
                                    <Header as="h4" className="profile-interest">
                                        Principal Architect @ Coditas | AWS Certified | Building Scalable Cloud Solutions | Passionate about Cloud & Data Engineering | Let's discuss how I can make your business more efficient 🚀
                                    </Header>
                                    <div className="profile-meta">
                                        <Icon name="map marker alternate" />
                                        Mumbai
                                    </div>
                                    <div className="profile-meta">
                                        <a href="https://www.linkedin.com/in/devanshpgupta" target="_blank" rel="noopener noreferrer" title="https://www.linkedin.com/in/devanshpgupta" className="profile-meta-linkedin"><Icon name='linkedin' />Connect me</a>
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={4} textAlign="center">
                                    <div className='contact-me'>
                                        {/* <a className='contact-me-button' href="/contact">
                                            <Icon name="mail" />
                                            Contact Me
                                        </a> */}
                                        <Link to="/contact" className='contact-me-button'>
                                            <Icon name="mail" />
                                            Contact Me
                                        </Link>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </div>
            </div>

        );
    }
}

export default ProfileHeader;