import { Component } from 'react';
import '../css/RevisedApp.css';
import 'semantic-ui-css/semantic.min.css';
import awsLogo1 from '../img/icon3.png'
import { Link, Outlet } from "react-router-dom";

interface RevisedAppProps {
    // Define your props here
}

interface RevisedAppState {
    enableMyProfile: boolean,
    askaquestion: string
}


class RevisedApp extends Component<RevisedAppProps, RevisedAppState>  {
    constructor(props: RevisedAppProps) {
        super(props);
        this.state = {
            enableMyProfile: false,
            askaquestion: ""
        };
    }

    onClickMyProfile = () => {
        this.setState((prevState) => ({
            enableMyProfile: !prevState.enableMyProfile
        }))
    };



    render() {
        const headerHTML: JSX.Element = (
            <header className="revised-header">
                <div className="logo">
                    <Link to="/profile">
                        <div className="logo-container">
                            <img src={awsLogo1} alt="Website Logo" className="circular-logo" />
                            <span className="site-name">Knowledge Draft</span>
                        </div>
                    </Link>
                </div>
                <nav className="nav">
                    {/* <Link to="/">Home</Link> */}
                    <Link to="/profile">My Profile</Link>
                    <Link to="/contact">Contact Me</Link>
                </nav>
            </header>
        )

        const bodyHTML: JSX.Element = (
            <div className="body">
                <Outlet />
            </div>
        )

        const footerHTML: JSX.Element = (
            <footer className="footer">
                <p>&copy; {new Date().getFullYear()} https://www.devanshpgupta.me. All Rights Reserved.</p>
            </footer>
        )

        return (
            <div className='RevisedApp'>
                {headerHTML}
                {bodyHTML}
                {footerHTML}
            </div>
        );
    }
}

export default RevisedApp;