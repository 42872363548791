import { Component } from 'react';
import '../css/WorkHistory.css';
import 'semantic-ui-css/semantic.min.css';
import {
  Icon,
  Container, Grid, Button, Header, Segment
} from "semantic-ui-react";

interface WorkHistoryProps {
  focusId: string
}

interface WorkHistoryState {
  visibleDescriptions: number[]
}

class WorkHistory extends Component<WorkHistoryProps, WorkHistoryState>  {
  constructor(props: WorkHistoryProps) {
    super(props);
    this.state = {
      visibleDescriptions: []
    };
  }
  
  scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  public componentDidUpdate(prevProps: WorkHistoryProps, prevState: WorkHistoryState): void {
    this.scrollToSection(this.props.focusId)
  }

  // Function to toggle visibility of a job description
  toggleDescription = (index: number) => {
    this.setState((prevState) => {
      const { visibleDescriptions } = prevState;

      // Check if the index is already in the array
      if (visibleDescriptions.includes(index)) {
        // Remove index if it's already visible
        return {
          visibleDescriptions: visibleDescriptions.filter((i) => i !== index),
        };
      } else {
        // Add index if not visible
        return {
          visibleDescriptions: [...visibleDescriptions, index],
        };
      }
    });
  };


  render() {
    const jobs = [
      {
        id: "principal-solution-architect", 
        title: "Principal Solution Architect",
        company: "Coditas Solutions LLP",
        location: "Mumbai, India",
        date: "08.2023 - Till Date",
        description: [
          "Collaborated with engineering, data science, and business analyst teams to analyze and fulfill data requirements, delivering integrated data solutions that enhanced system efficiency and workflow coherence for 3 major projects.",
          "Led the data engineering team in adopting best practices for data management and coding standards, resulting in a 20% improvement in performance optimization across 4 key projects.",
          "Implemented continuous monitoring and optimization strategies that improved data processing and storage efficiency by 30%, ensuring scalability for a 50% increase in data volume and complexity.",
          "Solved critical data-related challenges, such as discrepancies and integration bottlenecks, by innovating solutions that improved data reliability and usability.",
          "Professional Gatherings:",
          "Proven track record in software development and architecting complex systems.",
          "Strong technical expertise in software design patterns, technologies, and methodologies.",
          "Leadership abilities, driving teams towards achieving business-aligned technical goals."
        ]
        
      },
      {
        id: "senior-software-engineer",
        title: "Senior Software Engineer",
        company: "Coditas Solutions LLP",
        location: "Mumbai, India",
        date: "05.2022 - 07.2023",
        description: [
          "Designed and implemented scalable data models and architectures aligning with organizational objectives, enhancing data analytics capabilities, and efficiently managing large data volumes' ingestion, processing, and storage.",
          "Developed and maintained robust data pipelines for ETL processes, improving data extraction from diverse sources to data lakes, which enhanced data quality and accessibility for analytics, supporting 3+ business intelligence initiatives.",
          "Enhanced data warehouse modeling by designing efficient database schemas, facilitating improved data organization for querying and analysis, which streamlined reporting and decision-making processes for key stakeholders.",
          "Fostered collaboration with Product Management, Customer Support, Stakeholders, and QA teams, translating business requirements into high-quality services and ensuring rigorous testing, significantly reducing deployment errors by 40%.",
          "Authored and updated comprehensive technical documentation, covering data architectures and ETL processes, which improved team onboarding efficiency by 30% and facilitated better cross-functional understanding."
        ],
      },
      {
        id: "software-engineer",
        title: "Software Engineer",
        company: "LogicManager, INC",
        location: "Boston, MA",
        date: "10.2019 - 05.2022",
        description: [
          "Successfully implemented an ERM-based web framework, enhancing enterprise-level governance for a SaaS model, used by over 10,000 customers globally, demonstrating a risk-based approach.",
          "Implemented RESTful services using Spring and React/Redux with Typescript, significantly improving data access/integration and state management, and enhancing system reliability and user experience.",
          "Leveraged Jenkins and Nexus to streamline continuous integration, release processes, and production deployments, achieving a 40% reduction in deployment time and enhancing operational efficiency.",
          "Fitted and deployed a Python-based data extraction model for contract scanning, customizing data transformation for 100+ contracts weekly, optimizing data processing efficiency by 30%.",
          "Led code reviews, sprint demos, and backlog grooming, fostering an agile development environment that improved project delivery times by 25% and increased team productivity by 20%."
        ]
      },
      {
        id: "software-developer",
        title: "Software Developer",
        company: "United Health Group",
        location: "Boston, MA",
        date: "03.2018 - 10.2019",
        description: [
          "Successfully implemented and deployed an Automation Framework, streamlining the creation, execution, and validation of tests, which enhanced testing efficiency by 40% and coverage by 30%.",
          "Developed an ETL process using Apache Spark, processing over 1TB of real-time data daily from a source system, enhancing data accuracy and availability for analytics.",
          "Implemented a RESTful API, facilitating seamless communication with multiple endpoints, which improved system integration and increased data throughput by 20%.",
          "Crafted advanced SQL scripts, employing SQL Tuning and hierarchical queries, to generate critical reports, optimizing query performance by 50% and supporting strategic decision-making."
        ]
      },
      {
        id: "ptc-software-developer",
        title: "Software Engineer",
        company: "Precision Technologies Corp.",
        location: "Monmouth Junction, NJ",
        date: "08.2017 - 02.2018",
        description: [
          "Developed a web-tier application to automate job scheduling, executing files, scripts, and database procedures, coupled with an SFTP-based email distribution system, enhancing operational efficiency and reliability.",
          "Crafted an intuitive user interface leveraging ECMAScript, jQuery, Angular 2, CSS, Bootstrap, and HTML5, significantly improving user experience and accessibility across platforms."
        ]
      },
      {
        id: "vt-software-developer",
        title: "Software Engineer",
        company: "Vartak Info Tech",
        location: "Mumbai, India",
        date: "03.2014 - 07.2015",
        description: [
          "Developed programs for accessing the database using JDBC thin drivers to execute CRUD operations, and stored procedures and to manipulate the data in the database. Developed JSPs, custom tags and implemented JS validation."
        ]
      },
    ];

    const { visibleDescriptions } = this.state;

    return (
      <div className="work-history-section">
        <h2 className="work-history-title">
          <Icon name="suitcase" />
          Work History
        </h2>
        <Container className="work-history-container">
          {jobs.map((job, index) => (
            <Segment key={index} id={job.id} className="job-card">
              <div className="job-header">
                <Icon name="suitcase" className="job-icon" />
                <div className="job-details">
                  <h3 className="job-title">{job.title}</h3>
                  <p className="job-company">{job.company}</p>
                  <p className="job-location">{job.location}</p>
                  <p className="job-date">{job.date}</p>
                  {/* Toggleable Description */}
                  {visibleDescriptions.includes(index) && (
                    <div className="job-description">
                      <ul>
                        {job.description.map((point, i) => (
                          <li key={i}>{point}</li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <p
                    className="job-description-link"
                    onClick={() => this.toggleDescription(index)}
                  >
                    {visibleDescriptions.includes(index)
                      ? "Hide Description"
                      : "Show Description"}
                  </p>
                </div>
              </div>
            </Segment>
          ))}
        </Container>
      </div>

    );
  }
}

export default WorkHistory;