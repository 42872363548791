import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RevisedApp from "./RevisedApp";
import NewApp from "./NewApp"
import ContactForm from "./ContactForm"
import ProfileHeader from "./ProfileHeader"

class AppRoutes extends React.Component {
    render() {
        return (
            <Router>
                <Routes>
                    <Route path="/" element={<RevisedApp />}>
                        <Route index element={<NewApp />} />
                        <Route path="profile" element={<NewApp />}>
                            <Route path="contact" element={<ContactForm />} />
                        </Route>
                        <Route path="contact" element={<ContactForm />} />
                    </Route>
                </Routes>
            </Router>
        );
    }
};

export default AppRoutes;
