import { Component } from 'react';
import '../css/ProfessionalSummary.css';
import 'semantic-ui-css/semantic.min.css';
import {
    Container,
    Icon,
  } from "semantic-ui-react";

interface ProfessionalSummaryProps {
    // Define your props here
  }
  
  interface ProfessionalSummaryState {
  }

class ProfessionalSummary extends Component<ProfessionalSummaryProps, ProfessionalSummaryState>  {
    constructor(props:ProfessionalSummaryProps) {
        super(props);
        this.state = {
        };
    }


    render () {
        return (
            <div className="professional-summary-container">
                <section className="professional-summary">
                    <div className="professional-summary-heading">
                        <Icon enabled inverted fitted size="large" name='user outline'/>
                        <h2>Professional Summary</h2> 
                    </div>
                    <Container>
                        <p>
                            AWS Certified Solutions Architect with strong background in designing and implementing complex IT solutions. Skilled
                            in cloud architecture, systems integration, and implementing scalable, cost-efficient cloud solutions. Expertise in
                            architecting optimized data infrastructures on AWS, enhancing system performance, and aligning solutions with
                            business objectives. Proven track record in leading cross-functional teams to resolve complex data challenges and
                            deliver impactful results, including up to 50% improvements in data processing and storage efficiency. Skilled in AWS
                            services, data modeling, and automation strategies that drive operational excellence. Proven ability to drive
                            technological innovation and deliver high-quality solutions in fast-paced environments.
                        </p>
                    </Container>
                </section>
                <section className="overview">
                    <div className='professional-overview-heading'>
                        <Icon inverted fitted size="large" name='file archive outline'/>
                        <h2>Overview</h2>
                    </div>
                    <Container>
                        <div className="overview-items">
                            <div className="overview-item">
                                <div className="container-1">
                                    <div className="watermark-1">
                                        <div className="content-1">
                                            <span className="main-text-1">10</span>
                                            <p>YEARS OF <br/> PROFESSIONAL <br/> EXPERIENCE</p>
                                        </div>
                                        <span className="watermark-text-1">10</span>
                                    </div>
                                </div>
                            </div>
                            <div className="overview-item">
                                <div className="container-1">
                                    <div className="watermark-1">
                                        <div className="content-1">
                                            <span className="main-text-1">1</span>
                                            <p>CERTIFICATION</p>
                                        </div>
                                        <span className="watermark-text-1">1</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>

          );
    }
}

export default ProfessionalSummary;