import { Component } from 'react';
import '../css/Education.css';
import 'semantic-ui-css/semantic.min.css';
import {
  Icon,
  Container, Segment
} from "semantic-ui-react";

interface EducationProps {
  focusId: string
}

interface EducationState {
  visibleDescriptions: number[]
}

class Education extends Component<EducationProps, EducationState>  {
  constructor(props: EducationProps) {
    super(props);
    this.state = {
      visibleDescriptions: []
    };
  }

  scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  public componentDidUpdate(prevProps: EducationProps, prevState: EducationState): void {
    this.scrollToSection(this.props.focusId)
  }


  // Function to toggle visibility of a education description
  toggleDescription = (index: number) => {
    this.setState((prevState) => {
      const { visibleDescriptions } = prevState;

      // Check if the index is already in the array
      if (visibleDescriptions.includes(index)) {
        // Remove index if it's already visible
        return {
          visibleDescriptions: visibleDescriptions.filter((i) => i !== index),
        };
      } else {
        // Add index if not visible
        return {
          visibleDescriptions: [...visibleDescriptions, index],
        };
      }
    });
  };


  render() {
    const education = [
      {
        id: "edu-ms-cis",
        degree: "Master of Science - Computer and Information Sciences",
        university: "University of Massachusetts",
        location: "Dartmouth, MA",
        date: "05.2017",
        description: [
          "Graduation with Distinction, [Final Year, 2017]",
          "3.7 GPA"
        ]
      },
      {
        id: "edu-be-cs",
        degree: "Bachelor of Engineering - Computer Science",
        university: "University of Pune",
        location: "Pune",
        date: "06.2014",
        description: [
          "Relevant Courses: Data Structures & Algorithms, Advanced Databases, OOP, Distributed OS, AI, System Programming, and OS.",
          "3.7 GPA"
        ]
      },
    ];

    const { visibleDescriptions } = this.state;
    return (
      <div className="education-section">
        <h2 className="education-title">
          <Icon name="graduation cap" />
          Education
        </h2>
        <Container className="education-container">
          {education.map((edu, index) => (
            <Segment key={index} id={edu.id} className="education-card">
              <div className="education-header">
                <Icon name="graduation cap" className="education-icon" />
                <div className="education-details">
                  <h3 className="education-degree">{edu.degree}</h3>
                  <p className="education-university">{edu.university}</p>
                  <p className="education-location">{edu.location}</p>
                  <p className="education-date">{edu.date}</p>
                  {/* Toggleable Description */}
                  {visibleDescriptions.includes(index) && (
                    <div className="education-description">
                      <ul>
                        {edu.description.map((point, i) => (
                          <li key={i}>{point}</li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <p
                    className="education-description-link"
                    onClick={() => this.toggleDescription(index)}
                  >
                    {visibleDescriptions.includes(index)
                      ? "Hide Description"
                      : "Show Description"}
                  </p>
                </div>
              </div>
            </Segment>
          ))}
        </Container>
      </div>
    );
  }
}

export default Education;