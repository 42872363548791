import { Component } from 'react';
import '../css/Skills.css';
import 'semantic-ui-css/semantic.min.css';
import {
  Icon,
  List,
  Container
} from "semantic-ui-react";

interface SkillsProps {
  // Define your props here
}

interface SkillsState {
}

class Skills extends Component<SkillsProps, SkillsState>  {
  constructor(props: SkillsProps) {
    super(props);
    this.state = {
    };
  }


  render() {
    const skills = [
      [
        "Java",
        "Python",
        "TypeScript",
        "Oracle Cloud Infrastructure",
        "Redux",
        "Apache Spark",
        "BOTO3",
        "Spring Boot",
        "ElasticSearch",
        "Infrastructure automation",
        "Database design",
        "Disaster recovery",
      ],
      [
        "Scala",
        "JavaScript",
        "AWS",
        "React",
        "HTML/CSS/Bootstrap",
        "Spring MVC",
        "Maven",
        "Hibernate",
        "Apache Kafka",
        "Jenkins",
        "Software development"
      ],
      [
        "Apache ActiveMQ",
        "Docker",
        "Terraform",
        "SQL Developer",
        "Identity and Access management",
        "Storage solutions",
        "Microservices architecture",
        "Web services",
        "Ansible",
        "Oracle SQL / MySQL / SQL Server",
        "Toad Data Modeler"
      ]
    ];

    return (
      <div className="skills-section">
        <h2 className="skills-title">
          <Icon name="bolt" />
          Skills
        </h2>
        <Container className="skills-container">
          <div className="skills-grid">
            {skills.map((column, index) => (
              <List key={index} className="skills-column">
                {column.map((skill, i) => (
                  <List.Item key={i} className="skill-item">
                    <Icon name="dot circle" />
                    {skill}
                  </List.Item>
                ))}
              </List>
            ))}
          </div>
        </Container>
      </div>
    );
  }
}

export default Skills;