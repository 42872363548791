import { Component } from 'react';
import '../css/NewApp.css';
import 'semantic-ui-css/semantic.min.css';
import ProfessionalSummary from './ProfessionalSummary';
import WorkHistory from './WorkHistory'
import Education from './Education';
import Skills from './Skills';
import Certification from './Certification'
import Timeline from './Timeline'
import ProfileHeader from './ProfileHeader';

interface AppProps {
}

interface AppState {
    workHistoryFocusId: string,
    educationFocusId: string,
    certificationFocusId: string
}

class App extends Component<AppProps, AppState>  {
    constructor(props: AppProps) {
        super(props);

        this.state = {
            workHistoryFocusId: '',
            educationFocusId: '',
            certificationFocusId: ''
        };
        this.settingFocusId = this.settingFocusId.bind(this);
    }

    settingFocusId(id: string): void {
        console.log("id:" + id)
        if (id.includes("edu")) {
            this.setState({ educationFocusId: id });
        } else if (id.includes("cert")) {
            this.setState({ workHistoryFocusId: id });
        } else {
            this.setState({ workHistoryFocusId: id });
        }
    }

    render() {
        return (
            <div className='new-app'>
                <ProfileHeader />
                <ProfessionalSummary />
                <WorkHistory
                    focusId={this.state.workHistoryFocusId}
                />
                <Education
                    focusId={this.state.educationFocusId}
                />
                <Certification
                    focusId={this.state.certificationFocusId} />
                <Timeline
                    onWorkHistoryFocus={this.settingFocusId}
                />
                <Skills />
            </div>
        );
    }
}

export default App;