import React, { useState } from "react";
import { Form, Checkbox, Input, Button, Message } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import '../css/ContactForm.css';
import emailjs from "emailjs-com";

const ContactForm: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    reason: {
      schedule: false,
      job: false,
      info: false,
    },
    email: "",
    phone: "",
  });

  const [showDialog, setShowDialog] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^(\+?\d{1,2})?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

  const validateFields = (email: string, phone: string): { emailValid: boolean; phoneValid: boolean } => {
    const emailValid = emailRegex.test(email);
    const phoneValid = phone ? phoneRegex.test(phone) : true; // Phone is optional
    return { emailValid, phoneValid };
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleCheckboxChange = (field: keyof typeof formData.reason) => {
    setFormData({
      ...formData,
      reason: { ...formData.reason, [field]: !formData.reason[field] },
    });
  };

  const formMessage = (): string => {
    // Form the message
    const reasonMessage = [
      formData.reason.schedule && "Scheduling a meeting",
      formData.reason.job && "Job-related inquiry",
      formData.reason.info && "Requesting information",
    ]
      .filter(Boolean) // Remove false values
      .join(", ");

    return `
      Name: ${formData.name || "Not Provided"}
      Company: ${formData.company || "Not Provided"}
      Reasons: ${reasonMessage || "No reasons selected"}
      Email: ${formData.email || "Not Provided"}
      Phone: ${formData.phone || "Not Provided"}
    `;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const { email, phone } = formData;
    const { emailValid, phoneValid } = validateFields(email, phone);

    if (!emailValid) {
      alert("Please enter a valid email address.");
      return;
    }

    if (!phoneValid) {
      alert("Please enter a valid phone number.");
      return;
    }

    // EmailJS details
    const serviceId = "service_4p7pklq"; // Replace with your EmailJS service ID
    const templateId = "template_pzycsx3"; // Replace with your EmailJS template ID
    const privateKey = "znMdHEA3pFui-sVnT"; // Replace with your EmailJS private key
    const message = formMessage();

    // Sending the email
    emailjs
      .send(
        serviceId,
        templateId,
        {
          name: formData.name,
          email: formData.email,
          message: message,
        },
        privateKey
      )
      .then(
        (response) => {
          console.log("Email sent successfully!", response.status, response.text);
          setShowDialog(true); // Show the dialog
          setTimeout(() => {
            setShowDialog(false); // Hide the dialog after 3 seconds
            navigate("/profile"); // Redirect to /profile
          }, 3000);
        },
        (error) => {
          console.error("Failed to send email:", error);
        }
      );
  };

  return (
    <div className="contact-form-container">
      <h1>Contact form</h1>
      <p>
        Please complete the form below so we can forward your contact request to Devansh Gupta:
      </p>
      <Form className="contact-form" onSubmit={handleSubmit}>
        <div className="contact-header">
          <h2>Hi Devansh,</h2>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="First Name and Last Name*"
              placeholder="Jessica Claire"
              value={formData.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "name")}
              required
            />
            <Form.Field
              control={Input}
              label="Company Name"
              placeholder="Bed Bath & Beyond, Inc"
              value={formData.company}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "company")}
            />
          </Form.Group>
        </div>

        <div className="contact-reason">
          <p>I am contacting you because *:</p>
          <p className="reason-description">Tell them why you're reaching out:</p>
          <Form.Group grouped>
            <Form.Field
              control={Checkbox}
              label="Schedule a time to talk"
              checked={formData.reason.schedule}
              onChange={() => handleCheckboxChange("schedule")}
            />
            <Form.Field
              control={Checkbox}
              label="Discuss a job or project"
              checked={formData.reason.job}
              onChange={() => handleCheckboxChange("job")}
            />
            <Form.Field
              control={Checkbox}
              label="Request additional information"
              checked={formData.reason.info}
              onChange={() => handleCheckboxChange("info")}
            />
          </Form.Group>
        </div>

        <Form.Field
          control={Input}
          label="Email Address*"
          placeholder="formsample@example.com"
          value={formData.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "email")}
          required
          error={
            !emailRegex.test(formData.email) && formData.email.length > 0
              ? { content: "Please enter a valid email address.", pointing: "below" }
              : null
          }
        />
        <Form.Field
          control={Input}
          label="Phone Number"
          placeholder="(555) 432-1000"
          value={formData.phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "phone")}
        />

        <div className="contact-footer">
          <p>
            Looking forward to hearing from you! <br />
            <strong>{formData.name || "Jessica Claire"}</strong>
          </p>
          <Button type="submit" color="grey">
            SEND
          </Button>
        </div>

        <Message attached="bottom" info>
          By clicking "send", you accept our <a href="/terms">Terms of Service</a> and{" "}
          <a href="/privacy">Privacy Policy</a>. We will forward your contact request, however it
          is up to the receiver if the request is answered.
        </Message>
      </Form>
      {showDialog && (
        <div className="dialog-overlay">
          <div className="dialog-box">
            <p>Message sent successfully! Redirecting to your profile...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
