import { Component } from 'react';
import '../css/Certification.css';
import 'semantic-ui-css/semantic.min.css';
import {
    Icon,
    Container
  } from "semantic-ui-react";

interface CertificationProps {
    focusId: string
  }
  
  interface CertificationState {
  }

class Certification extends Component<CertificationProps, CertificationState>  {
    constructor(props:CertificationProps) {
        super(props);
        this.state = {
        };
    }

    scrollToSection = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      };
    
      public componentDidUpdate(prevProps: CertificationProps, prevState: CertificationState): void {
        this.scrollToSection(this.props.focusId)
      }

    render () {
        const awsCertificationId: string = "cert-aws-solutions-architect-associate"
        return (
            <div className="certification-section">
            <h2 className="certification-title">
                <Icon name="certificate" />
                Certification
            </h2>
            <Container className="certification-container" id={awsCertificationId}>
                    <div className="certification-item">
                        {`AWS Certified Solutions Architect, 11/01/24: `}
                        <a 
                            href="https://www.credly.com/badges/97436fbc-eb86-4f5d-aff6-4ab2c1f9c23c/public_url" 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            title="AWS Certificate - November 2024"
                            className="certification-link"
                        >
                            Link Here
                        </a>
                    </div>
            </Container>
            </div>
          );
    }
}

export default Certification;