import { Component } from 'react';
import '../css/Timeline.css';
import 'semantic-ui-css/semantic.min.css';
import {
    Icon,
} from "semantic-ui-react";

interface TimelineProps {
    onWorkHistoryFocus: (id:string) => void;
}

interface TimelineState {
}

class Timeline extends Component<TimelineProps, TimelineState>  {
    constructor(props: TimelineProps) {
        super(props);
        this.state = {
        };
    }

    scrollToSection = (id: string) => {
        this.props.onWorkHistoryFocus(id);
    };

    render() {
        const timeline = [
            {
                id: "principal-solution-architect", 
                title: "Principal Solution Architect",
                company: "Coditas Solutions LLP",
                date: "08.2023 - Till Date",
                year: "2024"
            },
            {
                id: "cert-aws-solutions-architect-associate",
                title: "AWS Certified Solutions Architect",
                company: "",
                date: "11/01/24",
                year: "2024"
            },
            {
                id: "senior-software-engineer",
                title: "Senior Software Engineer",
                company: "Coditas Solutions LLP",
                date: "05.2022 - 07.2023",
                year: "2023"
            },
            {
                id: "software-engineer",
                title: "Software Engineer",
                company: "LogicManager, INC",
                date: "10.2019 - 05.2022",
                year: "2022"
            },
            {
                id: "software-developer",
                title: "Software Developer",
                company: "United Health Group",
                date: "03.2018 - 10.2019",
                year: "2019"
            },
            {
                id: "ptc-software-developer",
                title: "Software Engineer",
                company: "Precision Technologies Corp.",
                date: "08.2017 - 02.2018",
                year: "2018"
            },
            {
                id: "edu-ms-cis",
                title: "MS - CIS",
                company: "University of Massachusetts",
                date: "",
                year: "2017"
            },
            {
                id: "vt-software-developer",
                title: "Software Engineer",
                company: "Vartak Info Tech",
                date: "03.2014 - 07.2015",
                year: "2015"

            },
            {
                id: "edu-be-cs",
                title: "BE - Computer Science",
                company: "University of Pune",
                date: "",
                year: "2014"
            },
        ];


        return (
            <div className="timeline-section">
                <h2 className="timeline-title">
                    <Icon name="briefcase" />
                    Timeline
                </h2>
                <div className="timeline-container">
                    {timeline.map((entry, index) => (
                        <div
                            key={index}
                            className={`timeline-item  ${index % 2 === 0 ? "left" : "right"}`}>
                            <div onClick={() => this.scrollToSection(entry.id)} className={`timeline-content ${index % 2 === 0 ? "left" : "right"}`}>
                                <div className='timeline-icon-boundary'>
                                    <Icon name="briefcase" className="timeline-icon" />
                                </div>
                                <div className='timeline-content-header'>
                                    <h3 className="timeline-title-item">{entry.title}</h3>
                                    <p className="timeline-company">{entry.company}</p>
                                    <p className="timeline-date">{entry.date}</p>
                                </div>
                            </div>
                            <div className="timeline-content-line">
                                {/* <Icon className="timeline-bullet" name="dot circle" /> */}
                                <label className="timeline-bullet">{entry.year}</label>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        );
    }
}

export default Timeline;